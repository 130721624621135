<template>
  <div>
    <div class="back-img">
      <div style="font-size: 50px; color: #fff">ACCOMMODATION</div>
    </div>

    <div class="comming-soon">
      Comming Soon...
    </div>
  </div>
</template>

<script>
export default {
  name: "Accomodation"
}
</script>

<style scoped>
.back-img{
  background-image: url("../assets/images/callfor/bgc1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.comming-soon{
  height: 200px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
</style>
